import { format, isValid, parse } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

const dateFormatMap: Record<string, string> = {
  user: "dd/MM/yyyy",
  system: "yyyy-MM-dd",
};

const dateTimeFormatMap: Record<string, string> = {
  user: "dd/MM/yyyy HH:mm",
  system: "yyyy-MM-dd HH:mm",
};

const dateTimeSecondFormatMap: Record<string, string> = {
  user: "dd/MM/yyyy HH:mm:SSS",
  system: "yyyy-MM-dd HH:mm:SSS",
};

const toDateObject = (dateStr: string) => {
  return parse(dateStr, dateFormatMap.system, new Date());
}

const formatDate = (dateStr: string | undefined, strFormat: string) => {
  const dateObj = parse(dateStr ?? "", dateFormatMap.system, new Date());
  return isValid(dateObj) ? format(dateObj, dateFormatMap[strFormat]) : "";
};

const formatDateTime = (dateStr: string | undefined, strFormat: string) => {
  const date = new Date(dateStr ?? "");
  const timeZone = 'Asia/Jakarta'
  const zonedDate = utcToZonedTime(date, timeZone)
  return isValid(zonedDate) ? format(zonedDate, dateTimeFormatMap[strFormat]) : "";
};

const toUserDateFormat = (dateStr: string) => formatDate(dateStr, "user");

const toSystemDateFormat = (dateStr: string) => formatDate(dateStr, "system");

const toUserDateTimeFormat = (dateTimeStr: string) => formatDateTime(dateTimeStr, "user");

export {
  dateFormatMap,
  dateTimeFormatMap,
  dateTimeSecondFormatMap,
  formatDate,
  toDateObject,
  toUserDateFormat,
  toUserDateTimeFormat,
  toSystemDateFormat,
};
